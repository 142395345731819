export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY = 'AUTH_VERIFY';
export const CHANGE_N_T = 'CHANGE_N_T';
export const AUTH_T = 'AUTH_T';
export const PROFILE_T = 'PROFILE_T';
export const CLASSES_T = 'CLASSES_T';
export const DATA_T = 'DATA_T';
export const LIMIT_T = 'LIMIT_T';
export const PAGE_NO_T = 'PAGE_NO_T';
export const RAMASS_TRANS = 'RAMASS_TRANS';
export const MAPSROUTES = 'MAPSROUTES';
export const ADDbUSCOLOR = 'ADDbUSCOLOR';
export const ADDMAPPOSITION = 'ADDMAPPOSITION';
export const HASH_PRESTA_ID = 'HASH_PRESTA_ID';
export const PRODID_T = 'PRODID_T';
