import React from 'react';
const Villes = React.lazy(() => import('../common/pages/Villes'));
const PointRelais = React.lazy(() => import('../common/pages/PointRelais'));
const DefectivesProducts = React.lazy(() => import('../common/pages/DefectivesProducts'));
const PrestaById = React.lazy(() => import('../common/pages/Prestataires/PrestaById'));
const Prestataires = React.lazy(() => import('../common/pages/Prestataires'));
const Login = React.lazy(() => import('../common/pages/Auth/Login'));
const Home = React.lazy(() => import('../common/pages/Home'));
const Orders = React.lazy(() => import('../common/pages/Commandes'));
const UsersRelayPoint = React.lazy(() => import('../common/pages/UsersRelayPoint'));

const routes = [
    {
        path: '/villes',
        exact: true,
        auth: true, 
        verify: true,
        component: Villes
    },
    {
        path: '/defective_products',
        exact: true,
        auth: true, 
        verify: true,
        component: DefectivesProducts
    },
    {
        path: '/point_relais',
        exact: true,
        auth: true, 
        verify: true,
        component: PointRelais
    },
    {
        path: '/prestataires/:id',
        exact: true,
        auth: true, 
        verify: true,
        component: PrestaById
    },
    {
        path: '/prestataires',
        exact: true,
        auth: true, 
        verify: true,
        component: Prestataires
    },
    {
        path: '/orders',
        exact: true,
        auth: true, 
        verify: true,
        component: Orders
    },
    {
        path: '/relayUser',
        exact: true,
        auth: true, 
        verify: true,
        component: UsersRelayPoint
    },
    {
        path: '/login',
        exact: false,
        auth: false, 
        verify: false,
        component: Login
    },
    {
        path: '/',
        exact: true,
        auth: true,
        verify: true,
        component: Home
    }
    
];

export default routes;