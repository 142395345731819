import axios from 'axios'
import store from './store'
import * as actions from './store/actions'


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

if (localStorage.getItem('jwt_token_ad')) {
	
	axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt_token_ad')}`;
} else {
	localStorage.removeItem('isAuthenticated');
	localStorage.removeItem('isVerify');
	localStorage.removeItem('jwt_token_admin')
}

axios.interceptors.response.use(
    response => response,
    (error) => {
        if(error.response.status === 401 ) {
            store.dispatch(actions.authLogout())
            window.location.href = `/login`;
        }
        return Promise.reject(error);
    }
);
export default axios;
